import React, {useState, useMemo, useEffect} from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels'

import { Bar } from 'react-chartjs-2';
import { Line } from 'react-chartjs-2';
import { useFetchData } from '../../../../hooks/useFetchData/useFetchData';
import dayjs from 'dayjs';
import { Autocomplete, Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Paper, Select, TextField, Typography } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import { DatePicker, LocalizationProvider, ptBR } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import './requestsChartsPage.css';
import RequestsTableModalCharts from '../../../../components/requests/requestsTable/requestsTableModalCharts';
import RequestAdmin from '../../../../components/requests/requestsModais/requestAdmin/requestAdmin';
import Loading from '../../../../components/loading/loading';

const IsDev = process.env.REACT_APP_AMBIENTE_DEV === 'true';
let url = process.env.REACT_APP_API+ "/requests/charts";
let urlUsers = process.env.REACT_APP_API+ "/user/all";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Colors,
    ChartDataLabels,
);

const months = [
    { value: 1, name: 'Janeiro'},
    { value: 2, name: 'Fevereiro'},
    { value: 3, name: 'Março'},
    { value: 4, name: 'Abril'},
    { value: 5, name: 'Maio'},
    { value: 6, name: 'Junho'},
    { value: 7, name: 'Julho'},
    { value: 8, name: 'Agosto'},
    { value: 9,  name: 'Setembro'},
    { value: 10,  name: 'Outubro'},
    { value: 11, name: 'Novembro'},
    { value: 12, name: 'Dezembro'},
];


const RequestsChartsPage = () => {
    const [chart, setChart] = useState('')
    const [data, setData] = useState({ datasets: []});
    const [animations, setAnimations] = useState(false);
    const [changed, setChanged] = useState(false);
    const [openConfig, setOpenConfig] = useState(false);
    const [requestsList, setRequestsList] = useState({});
    const [labelsCount, setLabelCount] = useState(0);
    const [requestsClick, setRequestsClick] = useState({});
    const [openRequest, setOpenRequest] = useState(false);
    const [values, setValues] = useState({});
    const [requestsClickOpen, setRequestsClickOpen] = useState(false);
    const [filters, setFilters] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,  
        option: 1,
        user: 0, 
        typeChart: 1,
        orientation: 'x',
        userName: '',
        FilterDate: false,
        InitialDate: dayjs().utc(true).format(), 
        FinalDate: dayjs().utc(true).format(),  
        orderChart: 'desc'     
    });
    const colorLabel = () => {
        let mode = localStorage.getItem('theme');
        return (mode === 'dark') ?  'white' : 'black';
    }

      const [period, setPeriod] = useState({
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1, 
    })

    const getTitleChart = () => {
        switch (filters?.option) {
            case 1: return 'Atendimentos Geral - Mês/Ano';
            case 2: return 'Atendimentos Geral por tipo - Mês/Ano';
            case 5: return 'Atendimentos Geral - Período';
            case 3: return 'Atendimentos por Usuário - Dia a Dia';
            case 4: return 'Total de Atendimentos - Período - Ord. Nome';
            case 6: return 'Total de Atendimentos - Período - Ord. Quantidade';
            default:
                break;
        }
    }
    

    const {  getDataDirectly, loading } = useFetchData();
    
    const handleChanged = () => {
        setChanged(!changed);
    }

    const handleCloseConfig = () => {
        setOpenConfig(false);
      }
    
      const handleOpenFilter = () => {
        setOpenConfig(true);
    }


    const { data : listUsers, editData } = useFetchData(urlUsers);

    const handleChangeFilters = (value) => {
        setFilters((prevValues) => (
            {
                ...prevValues, [value.target.name]: value.target.value,
            }
        ))
        if(value.target.name === 'option' && (value.target.value === 4 || value.target.value ===  6)){
            handleChangeOrientation('y')
        } else {
            handleChangeOrientation('x') 
        }
    }

    const handleChangeOrientation = (value) => {
        setFilters((prevValues) => (
            {
                ...prevValues, 'orientation': value,
            }
        ))
    }

    const handleChangeFiltersDate = (field, value) => {
   
        setFilters((prevValues) => (
          {...prevValues, 
            [field] : dayjs(value),}
        ));
    
    };

    const handleChangeUser = (values) => {
        setFilters((prevValues) => (
          {
              ...prevValues, "user": (!values) ? null : values.id,
        }));  
        setFilters((prevValues) => (
          {
              ...prevValues, "userName": (!values) ? '' : values.name,
        }));    
    }

    const handlerChangePeriod = (name, value) => {
        setPeriod((prevValues) => (
            {
                ...prevValues, [name]: value,
          }));      
    };
    
    const getDaysMonth = (year, month) => {
        const startDate = new Date(year, month - 1, 1);
        const endDate = new Date(year, month, 0);
        const numberDays = endDate.getDate();
        
        const dias = [];
        for (let day = 1; day <= numberDays; day++) {
            const diaFormatado = day.toString().padStart(2, '0');
            dias.push(diaFormatado);
        }
        
        return dias;
    };

    const getDays = () => {
        const startDate = new Date(filters?.InitialDate);
        const endDate =   new Date(filters?.FinalDate);
        const timeDiff = Math.abs(endDate.getTime() - startDate.getTime());

        const dias = [];
        for (let day = startDate; day <= endDate; day.setDate(day.getDate() + 1)) {
            const formattedDay = {
                dayFormat: dayjs(day).format('DD/MM'),
                day: dayjs(day)
            }
            dias.push(formattedDay);
        }        
        return dias;
    }
        
    
    function filterRequestsByDay(requests, date, nameUser, Type) {
        return requests && requests?.filter(requests => {
            switch (filters?.option) {
                case 1: return requests?.day === date;
                case 2: return requests?.day === date && requests?.type_request === Type;    
                case 3: return requests?.nameUser === nameUser && requests?.conclusion_day === dayjs(date).format('DD/MM/YYYY');
                case 5: return requests?.conclusion_day === dayjs(date).format('DD/MM/YYYY');
                default:
                    break;
            }
        }).length;
    };
    

    function filterRequestsByUsers(requests, nameUser) {
        return requests && requests?.filter(requests => {
            return requests?.nameUser === nameUser;
        }).length;
    }

    const getRequestsUsers = (requests) => {
     
        const usersRequests = requests && requests.map((request) => {
            return request?.nameUser
        });

        const usersUnique =  [...new Set(usersRequests)];

        return usersUnique;
    }


    const getDataChart = async (requests) => {

        let labels = (filters?.option <= 2) ? getDaysMonth(filters?.year, filters?.month) : getDays();

        let users = getRequestsUsers(requests);

        const getLabels = () => {
            switch (filters?.option) {
                case 1: return labels;
                case 2: return labels;
                case 3: return labels.map((date) => {return date.dayFormat});   
                case 4: return users;   
                case 5: return labels.map((date) => {return date.dayFormat});    
                case 6: return users;          
                default:
                    break;
            }
            
        };

        const datalabels = {
            color: 'white',
            anchor: 'center',
            align: 'center', 
            labels: {
                title: {
                    font: {
                        weight: 'bold',
                        size: 14,
                    },
                    textStrokeColor: 'black', 
                    textStrokeWidth: 4, 
                },
                value: {
                    display: false,
                },
            },
        };


        const getComparisonType = () => {
            switch (filters?.option) {
                case 1: return [
                    {
                        label: `Atendimentos`,
                        data: labels?.map((day) => {
                            return filterRequestsByDay(requests, day)
                        }),
                        borderWidth: 1,
                        datalabels: datalabels,
                    },
                ];
                case 2: return [
                    {
                        label: `Automação`,
                        data: labels?.map((day) => {
                            return filterRequestsByDay(requests, day, '', 0)
                        }),
                        borderWidth: 1,
                        datalabels: datalabels
                    },
                    {
                        label: `Ponto`,
                        data: labels?.map((day) => {
                            return filterRequestsByDay(requests, day, '', 1)
                        }),
                        datalabels: datalabels,
                        borderWidth: 1
                    },  
                                      
                ];
                case 3: {    
                    let dataSets = users.map((ds) => {
                            return {
                                label: `${ds}`,
                                // data: labels?.map((date) => {return filterRequestsByDay(requests, date.day, ds)}), 
                                data: labels?.map((date) => {return {'Nome': ds, 'Data': date.dayFormat, 'Total':  filterRequestsByDay(requests, date.day, ds)}}),
                                borderWidth: 1,
                                datalabels: datalabels
                            }
                    })
                    return dataSets;                  
                };
                case 4: return [
                    {
                        label: `Atendimentos`,
                        data: users?.map((user) => {
                            return filterRequestsByUsers(requests, user)
                        }),
                        borderWidth: 1,
                        datalabels: datalabels
                    },
                ];
                case 5: return [
                    {
                        label: `Atendimentos`,
                        data: labels?.map((date) => {
                            return filterRequestsByDay(requests, date.day)
                        }),
                        borderWidth: 1,
                        datalabels: datalabels,
                    },
                ];  
                case 6: return [
                    {
                        label: `Atendimentos`,
                        data: users?.map((user) => {
                            return filterRequestsByUsers(requests, user)
                        }),
                        borderWidth: 1,
                        datalabels: datalabels
                    },
                ];             
                default: return []
                    break;
            }
        }

        const cop = getComparisonType();

        console.log(cop);
        

       return new Promise( async (resolve, reject) => {
            const label =  getLabels();
            const datasets =  getComparisonType();
            setLabelCount(labels.length);
            resolve(
                setData( {
                    labels: label,
                    filter: labels,
                    datasets:  datasets,
                })
            )
       });
    }

    const handleOpenRequests = (date) => {
        setRequestsClickOpen(true);
    };

    const handleClickRequest = async (i) => {
        const date = data.filter[i[0].index];
        const user = data.datasets[i[0].datasetIndex].label;
        const userLabel = data.labels[i[0].index];
        const setRequests = () => {
            return new Promise((resolve, reject) => {
                let requests = {};
                switch (filters?.option) {
                    case 3: {
                       requests = requestsList.filter((r) => {
                            return r?.nameUser === user && r?.conclusion_day === dayjs(date.day).format('DD/MM/YYYY');
                       });
                       break;
                    };
                    case 4: {
                        requests = requestsList.filter((r) => {
                             return  r?.nameUser === userLabel;
                        });
                        break;
                     };      
                    default:
                        break;
                }

                resolve(setRequestsClick(requests));
            })
        };

        await setRequests();
        handleOpenRequests();
    }

    const updateFilter = async() => {
        await getDataDirectly(url, {
            chart: true,
            year: filters?.year,
            month: filters?.month,
            user: (filters?.option === 4) ? 0 : filters?.user,
            option: 1,
            FilterDate: filters?.option > 2,
            InitialDate: dayjs(filters?.InitialDate).utc(true).format(),
            FinalDate: dayjs(filters?.FinalDate).utc(true).format(), 
            order: (filters?.option === 6 || filters?.option === 3) ? 'count' : 'name', 
            orderChart: filters?.option === 3 ?  filters?.orderChart : 'desc'
        },
        async (response) => {
            setRequestsList(response);
            await getDataChart(response).then(() => {
                handleCloseConfig();
                handleChanged();
            });   
        }
        )
    }

    const handleChangeOrderChart = async (e) => {
        handleChangeFilters(e);
    }

    useEffect(() => {
        if (filters) {
            updateFilter();
        }
    }, [filters?.orderChart]);

    const getLegendPosition = () => {

        let position = 'top';
        let align = 'center';

        if(filters?.option > 2 || filters?.orientation === 'y'){
            align = 'start';
        }
        return {
            display: true,
            position: position,
            align: align
        }
    }; 

    const optionsHorizontal = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 5,
        indexAxis: 'x',
        parsing: {
            xAxisKey: 'Data',
            yAxisKey: 'Total'
        },
        plugins: {
            legend: getLegendPosition(),
            datalabels:  {
                anchor: 'end', 
                align: 'top',
                formatter: (value) => {
                   return  filters?.option === 3 ? value['Nome'] + ': ' + value['Total'] : value;
                },
                color: 'black', 
                font: {
                  size: 14,
                },
            },            
        },
        interaction: {
            mode: 'x'
        },
        onClick: (e, i) => {
            if( i.length > 0 && (filters?.option === 3 || filters?.option === 4)){
                handleClickRequest(i)
            }
        },
        animations: (animations) ? {
            tension: {
              duration: 1000,
              easing: 'linear',
              from: 1,
              to: 0,
              loop: true
            }
        } : {},
    };

    const optionsVertical = {
        responsive: true,
        maintainAspectRatio: false,
        aspectRatio: 2,
        indexAxis: 'y',
        plugins: {
            legend: getLegendPosition()
        },
        interaction: {
            mode:  'y'
        },
        onClick: (e, i) => {
            if( i.length > 0 && (filters?.option === 3 || filters?.option === 4 || filters?.option === 6)){
                handleClickRequest(i)
            }
        },
        animations: (animations) ? {
            tension: {
              duration: 1000,
              easing: 'linear',
              from: 1,
              to: 0,
              loop: true
            }
        } : {},
    };    

    const getWidthChart = () => {
        if(filters?.option === 1 || filters?.option === 2 || filters?.option === 5 
            || (filters?.option === 3 && labelsCount <= 31)
            || (filters?.option === 4 && labelsCount <= 31)
            || (filters?.option === 6 && labelsCount <= 31)
        ){
            if(filters?.option === 5 && labelsCount > 30){
                return `calc((97vw / 31) * ${labelsCount})` 
            } else {
                return '98vw';
            }         
        } else {
            if(filters?.user === 0){
                return `calc((90vw / 5) * ${labelsCount})` 
            } else if(labelsCount > 31) {
                return `calc((97vw / 31) * ${labelsCount})`  
            }
        }
    };   
    
    const getHeightChart = () => {
        if(filters?.option === 1 || filters?.option === 2 || filters?.option === 5 || filters?.option === 4 || filters?.option === 6  
        || (filters?.option === 3 && labelsCount <= 10)){
            if(filters?.option === 5 && labelsCount > 30){
                return `calc((80vh / 30) * ${labelsCount})` 
            } else if (filters?.option === 4 || filters?.option === 6){
                return `calc(80vh - 80px)` 
            } else {
                return `calc((80vh / 20) * ${labelsCount})` 
            }         
        } else {
            if(filters?.user > 0){
                if(labelsCount <= 15){
                    return 'calc(82vh - 80px)';
                } else {
                    return `calc((82vh / 20) * ${labelsCount})` ;
                } 
            } else {
                return `calc((82vh / 5) * ${labelsCount})` 
            }
            
        }
    };

    const chartContainerBodyH = {
        height: {xs: "calc(70vh - 80px)", sm: "calc(70vh - 80px)", md: "calc(70vh - 80px)", lg: "calc(75vh - 80px)", xl: "calc(82vh - 80px)"},
        width: getWidthChart(),
        overflowY: 'scroll',
        overflow: 'hidden',
        marginRight: '10px',
        display: (filters?.orientation === 'x') ? '' : 'none'
    };    

    const chartContainerBodyV = {
        height: {xs: "calc(70vh - 80px)", sm: "calc(70vh - 80px)", md: "calc(80vh - 80px)", lg: "calc(75vh - 80px)", xl: "calc(82vh - 80px)"},
        width:  '97vw',
        overflowY: 'hidden',
        overflow: (getHeightChart() == 'calc(82vh - 80px)' || (filters?.option === 4 || filters?.option === 6)) ? 'hidden' : 'scroll',
        marginRight: '0px',
        display: (filters?.orientation === 'y') ? '' : 'none'
    }; 

    const getChartByTypeHorizontal = () => {
        switch (filters?.typeChart) {
            case 1: return <Bar options={optionsHorizontal}  data={data} style={{display: 'inline-block', position: 'relative', width: '100%'}} />;
            case 2: return <Line options={optionsHorizontal}  data={data} style={{display: 'inline-block', position: 'relative', width: '100%'}} />;    
            default: break;
        }
    };

    const getChartByTypeVertical = () => {
        switch (filters?.typeChart) {
            case 1: return <Bar  options={optionsVertical}  data={data} style={{display: 'inline-block', position: 'relative', width: '100%'}} /> 
            case 2: return <Line options={optionsVertical}  data={data} style={{display: 'inline-block', position: 'relative', width: '100%'}} />   
            default: break;
        }
    };

    const LabelFilter  = useMemo(() => {
        if(filters?.option === 1 || filters?.option === 2 ){
            return (filters?.month) ? `${months[filters?.month -1].name}, ${filters?.year}` : 'Todos';
        } else {
            return `${dayjs(filters?.InitialDate).format('DD/MM/YYYY')} a ${dayjs(filters?.FinalDate).format('DD/MM/YYYY')}`
        }
    }, [changed]);  

    const ChartHorizontal = useMemo(() => {
        return getChartByTypeHorizontal();
    }, [changed]);  

    const ChartVertical  = useMemo(() => {
        return getChartByTypeVertical();
    }, [changed]);  


    if(window.innerWidth < 500){
        return (
            <Box sx={{width: '100%', textAlign: 'center', justifyContent: 'center', alignItems: 'center', mt: 20, userSelect: 'none',}}>
            <Box className="header-atendimento">
              <Typography variant="h4"  color="text.secondary" >
                Dispositivo incompatível.
              </Typography>
              <Typography color="text.secondary" gutterBottom>
                Acesse o Sirrus Atendimento do seu computador para visualizar os gráficos disponíveis.
              </Typography>
            </Box>
              <div >
                <img width={'100%'}  src="https://gifs.eco.br/wp-content/uploads/2022/10/gifs-de-graficos-0.gif" alt="Aguarde" />
              </div>
            </Box>
        )
    } else {
    return (    
        <Box sx={{height: '100%', width: '100%', p: 1, overflow: {xs: "scroll", sm: "scroll", md: "hidden", lg: "hidden"} }}> 
            <Box className="" sx={{ p: '2px 4px', display: 'flex',  mb: 2,  }}>
                <Grid container spacing={1} columns={ 12 } sx={{float: "center", width: '100%',}} >
                    {/* <Grid item xs={12} sm={12} md={12} lg={5} xl={5} sx={{ }} >
                        <Box sx={{ ml: 1, width: '100%'  }}>
                            <Typography variant="h4" sx={{ fontSize: 25 }}  color="text.secondary" >
                                Gráficos de Atendimentos
                            </Typography>
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                                Realize a analise dos atendimentos realizados através dos gráficos disponíveis.         
                            </Typography>
                        </Box>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={9} lg={5} xl={5} sx={{mt: 1, }} > 
                        <Paper elevation={3} sx={{width: '100%', p: 1, fontSize: 12}} >
                            <Typography >Gráfico: {getTitleChart()}</Typography>
                            <Typography>Período: {LabelFilter}</Typography> 
                            <Typography>Usuário: {(filters?.user) ? filters?.userName : 'Todos'}</Typography> 
                            <Typography>Total: {(requestsList?.length) ? requestsList?.length : '0'}</Typography> 
                        </Paper>
                    </Grid>  
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{mt: 1, mb: 1}} >
                        <Box sx={{           
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%', 
                                    flexDirection: 'row'
                                }}>
                            <Button
                                variant='outlined'
                                onClick={handleOpenFilter}
                                endIcon={<SettingsIcon />}
                                sx={{ mr: 1,   p: '10px' }}         
                            >   
                                Opções 
                            </Button>
                            <FormControl  sx={{mr: 1, display: (filters?.option === 3) ? 'flex' : 'none'}}>
                                <InputLabel id="orderChart">Ordem</InputLabel>
                                <Select
                                    labelId="orderChart"
                                    label="Ordem"
                                    fullWidth 
                                    name='orderChart'
                                    value={filters?.orderChart} 
                                    onChange={handleChangeOrderChart}
                                >
                                    <MenuItem value={'asc'}><em>Crescente</em></MenuItem>
                                    <MenuItem value={'desc'}><em>Decrescente </em></MenuItem>
                                </Select>
                            </FormControl>                             
                            {/* <Button
                                variant='outlined'
                                onClick={handlerChanged}
                                endIcon={<PublishedWithChangesIcon />}
                                sx={{ mr: 1,   p: '10px' }}
                            
                            >
                                Gerar
                            </Button>   */}
                        </Box>
                    </Grid>  
                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} sx={{mt: 1, mb: 1}} >
                        </Grid>                   
                </Grid>
            </Box>
            <div className="chartCard">
                <div className="chartBox">
                    <div className="chartContainer">
                        <Box sx={chartContainerBodyV}>
                            <Box sx={{height: getHeightChart(), overflowY: 'hidden'}}>
                            {ChartVertical}
                           </Box>                             
                        </Box>
                        <Box sx={chartContainerBodyH}>                    
                            {ChartHorizontal}
                        </Box>
                    </div>
                </div>
            </div>    
        <Dialog open={openConfig} onClose={handleCloseConfig} > 
        <DialogTitle>Opções de Gráficos</DialogTitle>
        <DialogContent> 
        <Loading open={loading} />           
        <Grid container spacing={1} columns={ 12 } sx={{float: "center"}} >
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{mt: 1, mb: 1, }} >
                <FormControl fullWidth>
                    <InputLabel id="chart-option">Qual informação deseja visualizar?</InputLabel>
                    <Select
                        labelId="chart-option"
                        label="Qual informação deseja visualizar?"
                        fullWidth 
                        name='option'
                        value={filters?.option} 
                        onChange={handleChangeFilters}
                    >
                        {/* <MenuItem value={1}><em>Atendimentos Geral - Mês/Ano</em></MenuItem> */}
                        <MenuItem value={2}><em>Atendimentos Geral por tipo - Mês/Ano</em></MenuItem>
                        <MenuItem value={5}><em>Atendimentos Geral - Período</em></MenuItem>
                        <MenuItem value={3}><em>Atendimentos por Usuário - Dia a Dia</em></MenuItem>
                        <MenuItem value={4}><em>Total de Atendimentos - Período - Ord. Nome</em></MenuItem>
                        <MenuItem value={6}><em>Total de Atendimentos - Período - Ord. Quantidade</em></MenuItem>
                    </Select>
                </FormControl>  
            </Grid>              
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{display: (filters?.option <= 2) ? 'none' : ''}}  >
                <LocalizationProvider dateAdapter={AdapterDayjs} localeText={ptBR.components.MuiLocalizationProvider.defaultProps.localeText}>
                    <DemoContainer components={['DatePicker']} >
                    <DatePicker 
                        label="Data Inicial" 
                        value={dayjs(filters.InitialDate).utc(IsDev)}
                        onChange={(newValue) => handleChangeFiltersDate('InitialDate', newValue)}
                        format="DD/MM/YYYY" 
                        sx={{width: '100%'}}
                    />
                    <DatePicker 
                        label="Data Final" 
                        value={dayjs(filters.FinalDate).utc(IsDev)}
                        onChange={(newValue) => handleChangeFiltersDate('FinalDate', newValue)}
                        format="DD/MM/YYYY" 
                        fullWidth
                        sx={{width: '100%'}}
                    />               
                    </DemoContainer>         
                </LocalizationProvider>       
            </Grid>             
            <Grid item xs={12} sm={12} md={7} lg={7} xl={7} sx={{mt: 1, mb: 1, display: (filters?.option > 2) ? 'none' : ''}} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart-period">Mês</InputLabel>
                    <Select
                        labelId="type-chart-period"
                        label="Mês"
                        fullWidth 
                        name='month'
                        value={filters?.month} 
                        onChange={handleChangeFilters}
                    >
                        {months && months.map((month) => { return ( <MenuItem value={month.value}><em>{month.name}</em></MenuItem> )})}     
                    </Select>
                </FormControl>  
            </Grid>  
            <Grid item xs={12} sm={12} md={5} lg={5} xl={5} sx={{mt: 1, mb: 1, display: (filters?.option > 2) ? 'none' : ''}} >
                <TextField
                    fullWidth
                    label="Ano"
                    type="number"
                    name='year'
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={filters?.year}
                    onChange={handleChangeFilters}
                /> 
            </Grid>   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} sx={{display: (filters?.option === 4 || filters?.option === 6) ? 'none' : ''}}>
                <Autocomplete
                disablePortal
                fullWidth
                options={listUsers}
                sx={{mt: 1}}
                getOptionLabel={ (option) => option['name'] || option }    
                value={filters?.userName}
                onChange={(event, values) => handleChangeUser(values)}
                renderInput={(params) => 
                <TextField
                    name="user"
                    {...params} label="Atendente" 
                />}
                />          
            </Grid>                   
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} >
                <FormControl fullWidth>
                    <InputLabel id="type-chart">Tipo de Gráfico</InputLabel>
                    <Select
                        labelId="type-chart"
                        label="Tipo de Gráfico"
                        fullWidth 
                        name='typeChart'
                        value={filters?.typeChart} 
                        onChange={handleChangeFilters}
                    >
                        <MenuItem value={1}><em>Barras</em></MenuItem>
                        <MenuItem value={2}><em>Linhas</em></MenuItem>
                    </Select>
                </FormControl>  
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} sx={{display: (filters?.option === 4 || filters?.option === 6) ? 'none' : ''}}>
                <FormControl fullWidth>
                    <InputLabel id="type-chart">Orientação</InputLabel>
                    <Select
                        labelId="orientation-chart"
                        label="Orientação"
                        fullWidth 
                        name='orientation'
                        value={filters?.orientation} 
                        onChange={handleChangeFilters}
                    >
                        <MenuItem value={'y'}><em>Horizontal</em></MenuItem>
                        <MenuItem value={'x'}><em>Vertical</em></MenuItem>
                    </Select>
                </FormControl>  
            </Grid>            
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} marginBottom={1} > 
            <FormControlLabel  control={
                <Checkbox
                    checked={animations}
                    onChange={(e) => setAnimations(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }}
                /> 
                } label="Gráfico Animado" 
            />
 
            </Grid>
        </Grid>
        </DialogContent>
        <DialogActions>
            <Button variant="outlined" color={'warning'} onClick={handleCloseConfig}>Fechar</Button>
            <Button variant="outlined" onClick={updateFilter}>Aplicar</Button>
        </DialogActions>        
        </Dialog>
        <RequestsTableModalCharts values={requestsClick} setOpen={setRequestsClickOpen} setOpenRequest={setOpenRequest} changed={changed} setValues={setValues} open={requestsClickOpen} handleChanged={updateFilter}/>
        <RequestAdmin values={values} open={openRequest} setValues={setValues} setOpenRequest={setOpenRequest} changed={changed} setChanged={setChanged} handleChanged={handleChanged}  />
        </Box>
    )};
}

export default RequestsChartsPage;