import { Autocomplete, Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, Fab, Grid, SpeedDial, TextField } from '@mui/material'
import React, { useState } from 'react'
import { useShowMessages } from '../../hooks/useMessages/useMessages';
import { useFetchData } from '../../hooks/useFetchData/useFetchData';
import { useCryptoData } from '../../hooks/useCryptoData/useCryptoData';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import FolderZipIcon from '@mui/icons-material/FolderZip';
import Loading from '../loading/loading';
import SaveIcon from '@mui/icons-material/Save';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import CancelIcon from '@mui/icons-material/Cancel';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

let url = process.env.REACT_APP_API+ "/files";


const FileUpload = (props) => {  
    const { decryptPermissionsTree } = useCryptoData();
    const user = decryptPermissionsTree(sessionStorage.getItem('data'));
    const { editData, postData, deleteData, loading } = useFetchData()
    const { ShowMessage, msgConfirm } = useShowMessages();
    const [disabledButtons, setDisabledButtons] = useState(false);
    const [errors, setErrors] = useState({});

    const handleChangeErros = (err, state) => {
      setErrors((prevErrors) => ({
          ...prevErrors,
          [err]: state
        }));
    };

    const handleChangeValues = (value) => {
        props.setValues((prevValues) => ({
        ...prevValues, 
        [value.target.name] : value.target.value,
        }));
        handleChangeErros([value.target.name], !value.target.value)
    };

    const handleChangeFile = (file) => {
      props.setFile(file);    
      props.setValues((prevValues) => ({
        ...prevValues, 
        ['file_name'] : file.name,
      }));  
      handleChangeErros('file', !file)
    }

    const handleClose = () => {
        props.setOpen(false)
    }

    const handleClickSend = async () => {

      if(!props.values?.title){
        handleChangeErros('title', true);
        return 
      };

      if(!props.values?.category){
        handleChangeErros('category', true);
        return 
      };

      let res = {};
      let time = 0;

      const formData = new FormData();
      formData.append('file', props?.file);
      formData.append('id', props?.values?.id);
      formData.append('title', props.values?.title);
      formData.append('file_name', props.values?.file_name);
      formData.append('description', props.values?.description);
      formData.append('path', props.values?.path);
      formData.append('category', props.values?.category);
      formData.append('user', user?.id);
      formData.append('size', props.values?.size);
      setDisabledButtons(true);
      try {
        if(props.editing){
          res =  await editData(url+'/upload', formData);
        }else{
          if(!props.file){
            handleChangeErros('file', true);
            return 
          }
          res = await postData(url+'/upload', formData);
        }

        if(res.success){
          ShowMessage('success', res.msg);
          props.handleChanged();    
          handleClose();   
        }else{
          ShowMessage('error', res.msg);
        }
       }
       finally {
        setDisabledButtons(false);
       }

    }

    const styleButtons = {
      width: 120,
      m: 1,
      fontSize: 11.5,
      textAlign: 'center',
  
    }

    const editEnabled = () => {
      return (!props.editing || props.values?.user === user?.id || user?.files_edit_all === 'Y')
    };

    const handleUploadClick = async(event) => {
        var file = event.target.files[0];
        handleChangeFile(file);
      };

    const handleDelete = async() => {
      try {
        msgConfirm('Exclusão de Arquivo', 'Confirma a exclusão do arquivo selecionado?',
          'Sim, pode excluir', 'Cancelar', async () => {
            setDisabledButtons(true);
            const res =  await deleteData(url+'/deleteFile', {id: props?.values.id}); 
            if(res.success){
                ShowMessage('success', res.msg);
                props.handleChanged();
                handleClose();
            }else{
                ShowMessage('error', res.msg);
            }
          })
      } finally {
        setDisabledButtons(false); 
      }
    }

    const handleChangeCategory = (values) => {
      props.setValues((prevValues) => (
        {
            ...prevValues, 'category': (!values) ? null : values.id,
      }));  
      props.setValues((prevValues) => (
        {
            ...prevValues, 'categoryName': (!values) ? null : values.name,
      })); 
      handleChangeErros('category', !values)
    }

    const getLinkDownload = (file_name, path) => {
      return `${url}/download?file_name=${encodeURI(file_name)}&path=${encodeURI(path)}`
     
    }

  return (
    <Dialog open={props.open}>
        <Loading open={loading} /> 
        <DialogTitle >Upload de Arquivos</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: 'absolute',
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <CloseIcon />
        </IconButton>
          <DialogContent sx={{width: '500px'}}>
            <TextField
              autoFocus
              name="title"
              defaultValue={props.values?.title}
              label="Título"
              type="text"
              helperText={(errors.title) ? "Informe o título do arquivo." : ''}
              error={errors.title}
              onBlur={handleChangeValues}
              fullWidth
              variant="outlined"
              sx={{mb: 2, mt: 2}}
            />   
            <TextField
              label="Descrição"
              multiline
              fullWidth                
              name='description'
              defaultValue={props?.values?.description}
              onBlur={handleChangeValues}
              placeholder='Informe detalhes sobre o arquivo'
              variant="outlined"   
              sx={{mb: 2}}
            />
            <Autocomplete
              sx={{mb: 2}}
              fullWidth
              options={props?.categories}
              getOptionLabel={ (option) => option['name'] || option }    
              value={props.values?.categoryName}
              onChange={(event, values) => handleChangeCategory(values)}
              renderInput={(params) => 
              <TextField
                helperText={(errors.category) ? "Informe a categoria do arquivo." : ''}
                error={errors.category}
                name="categoryName"
                {...params} label="Categoria" 
              />}
            />
            <Box sx={{display: "none"}}> 
              <input
                accept=".zip"             
                id="contained-button-file"
                multiple
                type="file"
                onChange={handleUploadClick}
              />
            </Box>
            <Box fullWidth sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
              <Chip 
                icon={<FolderZipIcon />} 
                label={(props.values.file_name) ? props.values.file_name : 'Nenhum arquivo selecionado'} 
                sx={{width: '86%', mr: 2}}
                title='Arquivo' 
              />
              <label htmlFor="contained-button-file"  >
                <Fab title="Selecionar Arquivo" component="span" disabled={disabledButtons || !editEnabled()} sx={(errors.file ) ? {color: 'red', border: '1px solid', borderColor: 'red'} : {}}>
                  <DriveFolderUploadIcon />
                </Fab>
              </label>
            </Box>
                
        </DialogContent>
        <DialogActions sx={{paddingRight: 3, }} >
            <Button variant='contained' color='error' disabled={disabledButtons || !editEnabled()} fullWidth
              startIcon={<DeleteForeverIcon />} 
              onClick={handleDelete} 
              sx={[styleButtons, {display: (props.editing) ? '' : 'none', }]} 
            >
              Excluir
            </Button> 
            <Button href={getLinkDownload(props?.values?.file_name, props?.values?.path)} 
              target="_blank" variant="contained" startIcon={<CloudDownloadIcon />} 
              sx={[styleButtons, {display: (props.editing) ? '' : 'none', }]} 
              color='success'
            >
              Download
            </Button>
            {/* <Button variant='contained' color='warning' fullWidth sx={{...styleButtons}} 
              startIcon={<CancelIcon />} 
              onClick={() => handleClose()} 
            >
              Fechar
            </Button>  */}
            <Button variant='contained' color='success' disabled={disabledButtons || !editEnabled()} fullWidth sx={{...styleButtons}} 
              startIcon={<SaveIcon />} 
              onClick={() => handleClickSend()} 
            >
              Salvar
            </Button> 
        </DialogActions>
      </Dialog>

  )
}

export default FileUpload;